// var sql = require('mssql');

export class DBAdapter{
    _conn;
    _DBConfig;
    _rpcClientAdapter;
    _rpcServerAdapter;
    _isServer = false;
    
    constructor(DBConfig, rpcClientAdapter, rpcServerAdapter) {
        this._DBConfig = DBConfig;
        // mqRPC adapter
        this._rpcClientAdapter = rpcClientAdapter;
        // mqServer adapter
        this._rpcServerAdapter = rpcServerAdapter;
        // track external
        window.$integration = {
            server: false,
            client: false 
        };
        window.$system = {
            connected: false
        }
        // register stream
        // sql.on('error', err => {
        //     throw new Error(err);
        // });
    }

    query(queryString, attributes = []) {

        return new Promise((resolve ) => {
            this._rpcClientAdapter({ 
                    queryString,
                    attributes
                },
                function rpcQueryResults(results) {
                    window.$integration.client = true;
                    // resolve response
                    resolve(results);
                }
            );
        });
    }

}